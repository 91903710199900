@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #111835;
}

.App {
  font-family: 'Oswald', sans-serif !important;
}

#heroSection {
  height: 100vh;
  background-color: #111835;
}

header {
  padding: 1rem 0rem;
  background-color: #111835;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}

#headerLogoContainer {
  margin-left: 5rem;
}

#headerNavLinksContainer {
  margin-right: 5rem;
}

.primaryBtn {
  background-color: #005EE1;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.primaryBtnOutline {
  background-color: transparent;
  box-shadow:0px 0px 0px 3px #005EE1 inset;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.secondaryBtn {
  background-color: #ef4826;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

#heroBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: -72px;
}

#heroBanner h1{
  color: #FFFFFF;
  font-size: 88px;
  font-weight: 700;    
  margin: 0px;
}

#heroBanner p{
  color: #ef4826;
  font-size: 18px;
  font-weight: 500;    
  margin: 0px;
  margin-bottom: 2rem;
  text-align: center;
}

.card {
  border-radius: 20px;
  background-color: #111835;
  padding: 1rem;
  color: #FFFFFF;
}

#navBtn {
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  background-color: transparent;
  background-color: #005EE1;
  border: none;
  color: #FFFFFF;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.navLanguageToggle {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins', sans-serif !important;
  margin-right: 1.5rem;
}

.navLanguageToggle p {
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.navLanguageToggle p.active {
  font-weight: 600;
  color: #FABB00;
}

footer {
  padding: 0px 5rem;
  padding-top: 0px;
  background-color: #111835;
}

footer p {
  font-size: 14px;
  margin: 0px;
  /* color: #ef4826; */
  color: white;
  font-weight: 400;
}

p#logoText {
  margin: 0px;
  font-weight: bold;
  font-size: 30px;
  /* color: #ef4826; */
  color: white;
}

#earlySignupSection {
  background-color: #111835;
  padding: 2rem 5rem;
}

#earlySignupSection h1, #earlySignupSection p {
  color: #FFFFFF;
}

#earlySignupSection button {
  background-color: #005EE1;
  border-color: #005EE1;
}

h1 {
  color: #005EE1;
  margin: 0px;
  font-size: 40px;
}

p {
  margin: 0px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif !important;
}

input.mainInput, select.mainInput, textarea.mainInput {
  border: 1px solid #d2d2d7;
  border-radius: 20px;
  outline: none;
  height: 50px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

input.mainInput::placeholder, textarea.mainInput::placeholder {
  color: #999999;
}

.inputFieldButtonGroup {
  display: flex;
  flex-direction: row;
}

.inputFieldButtonGroup input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.inputFieldButtonGroup button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #005EE1;
  width: 200px;
}

#contactUsSection {
  padding: 2rem 5rem;
  background-color: #FFFFFF;
}

#contactUsSection h1 {
  
}

#contactUsSection p {
  
}

#footerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#footerRow a {
  padding: 1rem;
  background-color: #005EE1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-left: 1rem;
}

#footerRow svg {
  color: #FFFFFF;
  width: 20px;
  height: 20px;
}

#featuresSection {
  padding: 2rem 5rem;
  background-color: #FFFFFF;
}

h2 {
  margin: 0px;
}

#featuresSection .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0.5rem;
}

#featuresSection .card h1 {
  font-size: 80px;
  margin-right: 2rem;
  margin-top: -1rem;
  margin-bottom: -0.75rem;
  color: #FABB00;
}

#prizesSection {
  padding: 0px 5rem;
  background-color: #FFFFFF;
}

#heroSection video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

#heroSection:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(180deg, rgba(17, 24, 53, 1) 10%, rgba(17, 24, 53,0.8) 90%, rgba(17, 24, 53,0.8) 100%); */
  background: rgba(17, 24, 53, 0.7);
  /* backdrop-filter: blur(10px); */
  z-index: 1;
}

.swal2-modal {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 20px !important;
}

div:where(.swal2-icon).swal2-warning {
  color: #111835 !important;
  border-color: #111835 !important;
}

.swal2-confirm {
  height: 50px !important;
  border-radius: 20px !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 20px !important;
}

.loadingPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

#heroText {
  position: absolute;
  top: 0px;
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

#heroText p {
  color: #FFFFFF;
}

#heroSubtext {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  margin-left: 5rem;
  margin-right: 5rem;
}

#heroMaintext {
  font-size: 100px;
  font-weight: 700;
  font-family: 'Oswald', sans-serif !important;
  line-height: 100px;
  margin-left: 5rem;
  margin-right: 5rem;
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar {
  display: none;
}

#languageModal {
  z-index: 1000;
}

.modal-content {
  border-radius: 20px !important;
  background-color: #111835 !important;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-content h1 {
  color: #FFFFFF !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  margin: 0px !important;
  font-family: 'Oswald', sans-serif !important;
  margin-bottom: 1rem !important;
}

.modalHeader svg{
  color: #005EE1;
  height: 30px;
  width: auto;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  header {
    padding: 1rem 0rem;
  }

  #heroBanner {
    padding: 1rem 1rem;
    padding-bottom: 2rem;
  }

  #heroBanner h1{
    font-size: 40px;
  }

  #featuresSection {
    padding: 1rem;
    border-bottom: 1px solid #FFFFFF;
  }

  #featuresSection svg {
    padding-left: 0px;
    padding-right: 24px;
  }

  #prizesSection {
    padding: 1rem;
    border-bottom: 1px solid #FFFFFF;
  }

  footer {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #earlySignupSection {
    padding: 2rem 1rem;
  }

  #contactUsSection {
    padding: 2rem 1rem;
  }

  #footerRow {
    flex-direction: column;
    padding-bottom: 2rem;
  }

  #footerRow p {
    margin-bottom: 1rem;
  }

  .navLanguageToggle {
    margin-right: 1rem;
  }

  #headerLogoContainer {
    margin-left: 1rem;
  }
  
  #headerNavLinksContainer {
    margin-right: 1rem;
  }

  #heroText {
    align-items: flex-start;
  }

  #heroSubtext {
    font-size: 25px;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: -79.5px;
  }
  
  #heroMaintext {
    font-size: 80px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}